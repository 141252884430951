<template>

  <div class="right-menu shipping-right">

    <ValidationObserver ref="addShippingObserver">
      <div class="db-blue-section" v-loading.fullscreen.lock="shippingLoader">

        <div class="db-container">

          <subHeader pageName="ADD SHIPPING"/>

          <form id="add-shipping-information" @submit.prevent="validate('update')">

            <!-- region basic information block starts here -->
            <div class="shipping-box" id="basic-info">
              <div class="row">
                <div class="col-md-12">

                  <!-- region name block starts here -->
                  <div class="RegionTitleBox">
                    <h3>REGION DETAIL</h3>
                    <p>Add the Destination in this Region</p>
                  </div>
                  <div class="mainEditHeading">
                    <label for="region-name">Region Name <span>*</span></label>
                    <ValidationProvider name="region name" :rules="{required:true,max:60}" v-slot="{ errors }">
                      <input 
                        type="text" 
                        v-model="region_name" 
                        placeholder="Region name" 
                        id="region-name" 
                        :disabled="tourIsActive"
                      >
                      <br>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <!-- region name block ends here -->

                  <!-- destination block starts here -->
                  <div class="region-content">
                    
                    <!-- country block starts here -->
                    <div class="regionLabel">
                      <label for="countries">Countries <span>*</span></label>
                    </div>
                    <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                      <div class="vueSelectBlock" id="countries-selection">
                        <Multiselect
                          v-model="selectedCountries"
                          id="countries"
                          :clearOnSelect="true"
                          :options="countriesList ? countriesList : []"
                          label="name"
                          :multiple="true"
                          :object="true"
                          track-by="name"
                          :hideSelected="false"
                          :showNoResults="true"
                          placeholder="Select Country"
                          :showOptions="true"
                          :searchable="true"
                          openDirection="bottom"
                          @remove="countryRemoval"
                          :disabled="tourIsActive"
                        >
                          <span slot="noResult">No country found matching the searched keyword.</span>
                        </Multiselect>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <!-- country block ends here -->
                    
                    <!-- states block starts here -->
                    <template v-if="statesGroup.length != 0">
                      <div class="regionLabel">
                        <label for="states">States <span>*</span></label>
                      </div>
                      <ValidationProvider name="states" rules="required" v-slot="{ errors }">
                        <Multiselect
                          v-model="selectedStates"
                          id="states"
                          :clearOnSelect="true"
                          :options="statesGroup"
                          group-values="states" 
                          group-label="country_name" 
                          :group-select="true"
                          label="name"
                          track-by="name"
                          :hideSelected="false"
                          :showNoResults="true"
                          :multiple="true"
                          :object="true"
                          :showOptions="true"
                          :searchable="true"
                          :loading="statesLoading"
                          openDirection="bottom"
                          :placeholder="statesLoading ? 'Loading States....' : 'Select States'"
                          @remove="stateRemoval"
                          :disabled="tourIsActive"
                        >
                          <span slot="noResult">No state found matching the searched keyword.</span>
                          <span slot="noOptions">To load the states, please select a country.</span>
                        </Multiselect>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </template>
                    <!-- states block ends here -->

                    <!-- cities block starts here -->
                    <template v-if="citiesGroup.length != 0">
                      <div class="regionLabel">
                        <label for="cities">Cities <span>*</span></label>
                      </div>
                      <ValidationProvider name="cities" rules="required" v-slot="{ errors }">
                        <Multiselect
                          v-model="selectedCities"
                          id="cities"
                          :clearOnSelect="true"
                          :options="citiesGroup"
                          group-values="cities" 
                          group-label="state_name"
                          :group-select="true"
                          label="name"
                          track-by="name"
                          :hideSelected="false"
                          :showNoResults="true"
                          :multiple="true"
                          :object="true"
                          :showOptions="true"
                          :searchable="true"
                          openDirection="bottom"
                          @remove="cityRemoval"
                          :loading="citiesLoading"
                          :placeholder="citiesLoading ? 'Loading Cities....' : 'Select Cities'"
                          :disabled="tourIsActive"
                        >
                          <span slot="noResult">No city found matching the searched keyword.</span>
                          <span slot="noOptions">To load the cities, please select a state.</span>
                        </Multiselect>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </template>
                    <!-- cities block ends here -->

                    <button type="button">Add Region</button>

                  </div>
                  <!-- destination block ends here -->

                </div>
              </div>
            </div>
            <!-- region basic information block ends here -->

            <!-- region advance information block starts here -->
            <div class="shipping-box" id="advance-info">
              <div class="row">
                <div class="col-md-12">
                  <h2>SHIPPING CALCULATION</h2>
                  <div class="paymentForm">
                    <div class="region-content">
                      <div class="advanceChecked" id="cod-support-checkbox">
                        <input
                          type="checkbox"
                          v-model="isCodSupport"
                          :disabled="tourIsActive"
                          name="defaultUnchecked1"
                          id="defaultUnchecked1"
                        />
                        <label for="defaultUnchecked1">Do You Support COD payment method.</label>
                      </div>
                    </div>
                    <div class="region-content" v-show="isCodSupport">
                      <div class="advanceChecked" id="non-cod-different">
                        <input
                          type="checkbox"
                          v-model="isNonCodDifferent"
                          name="defaultUnchecked2"
                          id="defaultUnchecked2"
                          :disabled="tourIsActive"
                        />
                        <label for="defaultUnchecked2">Are the charges different for COD and non-COD?</label>
                      </div>
                    </div>
                    <div class="notePargraph" v-show="isCodSupport && isNonCodDifferent">
                      <p>
                        <span>Note</span> Now you can add different prices For COD
                        on the Shipment Page.
                      </p>
                    </div>
                  </div>
                  <div class="region-content" style="margin-top: 30px">
                    <p>Select How Shipping Rates Are Calculated In This Region</p>
                  </div>
                </div>
              </div>

              <!-- shipping methods block starts here -->
              <div class="row mdScreensBlock">
                <div class="col-md-12 col-lg-6">

                  <!-- active shipping method block starts here -->
                  <div class="select-menu" id="active-shipping-method" @click="toggleDropDown()">
                    <div class="select-img">
                      <img :src="activeShipping.img" />
                    </div>
                    <div class="free-ship-content">
                      <h3>{{ activeShipping.title }}</h3>
                      <p>{{ activeShipping.description }}</p>
                      <p v-if="activeShipping.note" style="color:red">{{ activeShipping.note }}</p>
                    </div>
                    <div class="select-menu-arrow">
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </div>
                  </div>
                  <!-- active shipping method block ends here -->

                  <!-- shipping methods drop down block starts here -->
                  <div class="dropdown-calculation" v-if="showDropDown">
                    <div class="select-menu-dropdown" @click.prevent="changeOption(shipping)" v-for="shipping in shippingOptions" :key="shipping.id">
                      <div class="select-img">
                        <img :src="shipping.img" />
                      </div>
                      <div class="free-ship-content">
                        <h3>{{ shipping.title }}</h3>
                        <p>
                          {{ shipping.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- shipping methods drop down block ends here -->

                </div>
              </div>
              <!-- shipping method forms start here -->

              <form action="" class="shippingDetailForm">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    
                    <!-- flat rate form starts here -->
                    <div class="flatrateBox" v-show="activeShipping.id == 5" id="rate-by-weight">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <hr v-show="rule_index > 0" />
                        <div class="cancelBtn">
                          <i class="fa fa-times" aria-hidden="true" @click="removeItem(item,rule_index)"></i>
                        </div>
                        <ValidationProvider name="shipping option name" :vid=" item ? 'D-name' + item.id : 'D-name' " :rules="activeShipping.id == 5 ? {required:true,max:250} : ''" v-slot="{ errors }">
                          <div class="tableFromFieldBox mb-0">
                            <label :for="`doName + ${item.id}`">Shipping Option Name <span>*</span></label>
                          </div>
                          <div class="exclimatoryForm">
                            <input
                              type="text"
                              :id="`doName + ${item.id}`"
                              class="form-control"
                              placeholder="e.g. Standard shipping"
                              v-model="item.name"
                              :disabled="tourIsActive"
                              @input="formatHandle('option name',item)"
                              @blur="checkDuplicateShippingOption(item)"
                            />
                            <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <div class="row">
                          <div :class="isNonCodDifferent ? 'col-md-6' : 'col-md-7'">
                            <ValidationProvider name="duration" :vid="item ? 'FR-duration' + item.id : 'FR-duration'" :rules="activeShipping.id == 5 ? {required:true,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`doDays + ${item.id}`">Est. Delivery Time <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`doDays + ${item.id}`"
                                  class="form-control"
                                  placeholder="e.g. 3 - 5 Delivery days"
                                  v-model="item.delivery_details"
                                  @input="formatHandle('delivery details',item)"
                                  @blur="trimHandle('delivery details',item)"
                                  :disabled="tourIsActive"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div :class="isNonCodDifferent ? 'col-md-3': 'col-md-5'">
                            <ValidationProvider name="price" :vid="item ? 'FRCOD-price' + item.id : 'FRCOD-price'" :rules="activeShipping.id == 5 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:999999} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`FCODprice + ${item.id}`">Price <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  class="form-control"
                                  :id="`FCODprice + ${item.id}`"
                                  :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                  v-model="item.price"
                                  :disabled="tourIsActive"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-3" v-if="isNonCodDifferent">
                            <ValidationProvider name="non code price" :vid="item ? 'FRNonCOD-price' + item.id : 'FRNonCOD-price'" :rules="activeShipping.id == 5 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:999999} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`FNonCODprice + ${item.id}`">Price (Non-COD) <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  class="form-control"
                                  :id="`FNonCODprice + ${item.id}`"
                                  :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                  v-model="item.non_cod_price"
                                  :disabled="tourIsActive"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click.prevent="addMoreForm" :disabled="tourIsActive">+ Add Another Shipping Option</button>
                      </div>
                    </div>
                    <!-- flat rate form ends here -->

                    <!-- rate by weight form starts here -->
                    <div class="rateByWeightForm" v-show="activeShipping.id == 2">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <ValidationProvider name="shipping option" :vid="item ? 'name' + item.id : 'name' " :rules="activeShipping.id == 2 ? {required:true,max:250} : ''" v-slot="{ errors }">
                          <div class="cancelBtn">
                            <i class="fa fa-times" aria-hidden="true" @click="removeItem(item,rule_index)"></i>
                          </div>
                          <div class="tableFromFieldBox mb-0">
                            <label :for="`rBW + ${item.id}`">Shipping Option Name <span>*</span></label>
                          </div>
                          <div class="exclimatoryForm">
                            <input
                              type="text"
                              :id="`rBW + ${item.id}`"
                              class="form-control"
                              placeholder="e.g. Standard shipping"
                              v-model="item.name"
                              @input="formatHandle('option name',item)"
                              @blur="checkDuplicateShippingOption(item)"
                            />
                            <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <div class="row">
                          <div class="col-md-4">
                            <ValidationProvider name="duration" :vid="item ? 'duration' + item.id : 'duration'" :rules="activeShipping.id == 2 ? {required:true,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`rBW_DDays + ${item.id}`">Est. Delivery Time <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`rBW_DDays + ${item.id}`"
                                  class="form-control"
                                  placeholder="e.g. 3 - 5 Delivery Days"
                                  @input="formatHandle('delivery details',item)"
                                  @blur="trimHandle('delivery details',item)"
                                  v-model="item.delivery_details"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div :class="weight_index == 0 ? 'col-md-8':'offset-md-4 col-md-8'" v-for="(weight, weight_index) in item.weights" :key="weight_index">
                            <div class="exclimatoryForm">
                              <div class="weightRangeMainBox">
                                <div class="weightRangeCol">
                                  <div class="tableFromFieldBox mb-0">
                                    <label for="doName">Weight Range <span>*</span></label>
                                  </div>
                                  <div class="weightRange2">
                                    <ValidationProvider name="from" :vid="weight ? 'range-from' + weight.id : 'range-from'" :rules="activeShipping.id == 2 && weight_index != 0 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value:0}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          placeholder="from"
                                          v-model="weight.from"
                                          :disabled="weight_index == 0 ? true: false"
                                          @input="inpFrom(weight_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <ValidationProvider name="to" :vid="weight ? 'range-to' + weight.id : 'range-to'" :rules="activeShipping.id == 2 && weight_index != item.weights.length - 1 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value: (parseFloat(weight.from) + 0.01).toFixed(2)}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          :placeholder="weight_index == item.weights.length-1 ? 'And Upto' : 'to'"
                                          v-model="weight.to"
                                          :disabled="weight_index == item.weights.length-1 ? true: false"
                                          @input="inpTo(weight_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                </div>
                                <div class="weightRange3">
                                  <ValidationProvider name="price" :vid="weight ? 'RBWCODprice' + weight.id : 'RBWCODprice'" :rules="activeShipping.id == 2 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBWCODprice + ${weight.id}`">Price <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="number"
                                        :id="`RBWCODprice + ${weight.id}`"
                                        class="form-control"
                                        :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                        v-model="weight.price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </div>
                                <div class="weightRange3" v-if="isNonCodDifferent">
                                  <ValidationProvider name="non code price" :vid="weight ? 'RBWNonCODprice' + weight.id : 'RBWNonCODprice'" :rules="isNonCodDifferent && activeShipping.id == 2 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBWNonCODprice + ${weight.id}`">Price (Non-COD) <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="number"
                                        :id="`RBWNonCODprice + ${weight.id}`"
                                        class="form-control"
                                        :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                        v-model="weight.non_cod_price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </div>
                                <div class="closeRangeBtn" v-if="weight_index > 1">
                                  <i class="fa fa-times" aria-hidden="true" @click="removeSubItem(item,weight,weight_index)"></i>
                                </div>
                                <div class="addField" v-show="weight_index == (item.weights.length - 1)">
                                  <button type="button" @click.prevent="addFields(weight_index, rule_index)"><span>+</span></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click="addMoreForm">+ Add Another Shipping Option</button>
                      </div>
                    </div>
                    <!-- rate by weight form ends here -->

                    <!-- rate by price form starts here -->
                    <div class="rateByWeightForm" v-show="activeShipping.id == 3">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <ValidationProvider name="shipping option" :vid="'name' + rule_index" :rules="activeShipping.id == 3 ? {required:true,max:250} : ''" v-slot="{ errors }">
                          <div class="cancelBtn">
                            <i class="fa fa-times" aria-hidden="true" @click="removeItem(item,rule_index)"></i>
                          </div>
                          <div class="tableFromFieldBox mb-0">
                            <label :for="`rBP_SName-${rule_index}`">Shipping Option Name <span>*</span></label>
                          </div>
                          <div class="exclimatoryForm">              
                            <input
                              type="text"
                              :id="`rBP_SName-${rule_index}`"
                              class="form-control"
                              placeholder="e.g. Standard shipping *"
                              v-model="item.name"
                              @input="formatHandle('option name',item)"
                              @blur="checkDuplicateShippingOption(item)"
                            />
                            <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <div class="row">
                          <div class="col-md-4">
                            <ValidationProvider name="duration" :vid="'duration' + rule_index" :rules="activeShipping.id == 3 ? {required:true,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`rBP_DDays-${rule_index}`">Est. Delivery Time <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`rBP_DDays-${rule_index}`"
                                  class="form-control"
                                  placeholder="e.g. 3 - 5 Delivery days"
                                  @input="formatHandle('delivery details',item)"
                                  @blur="trimHandle('delivery details',item)"
                                  v-model="item.delivery_details"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div :class="price_index == 0 ? 'col-md-8':'offset-md-4 col-md-8'" v-for="(listedPrice, price_index) in item.prices" :key="price_index">
                            <div class="exclimatoryForm">
                              <div class="weightRangeMainBox">
                                <div class="weightRangeCol">
                                  <div class="tableFromFieldBox mb-0">
                                    <label>Price Range <span>*</span></label>
                                  </div>
                                  <div class="weightRange2">
                                    <ValidationProvider name="from" :vid="`range-from-${rule_index}${price_index}`" :rules="activeShipping.id == 3 && price_index != 0 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value: 0}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          placeholder="from"
                                          v-model="listedPrice.from"
                                          :disabled="price_index == 0 ? true: false"
                                          @input="inpFrom(price_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <ValidationProvider name="to" :vid="`range-to-${rule_index}${price_index}`" :rules="activeShipping.id == 3 && price_index != item.prices.length - 1 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value: (parseFloat(listedPrice.from) + 0.01).toFixed(2)}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          :placeholder="price_index == item.prices.length-1 ? 'And Upto' : 'to'"
                                          v-model="listedPrice.to"
                                          :disabled="price_index == item.prices.length-1 ? true: false"
                                          @input="inpTo(price_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                </div>
                                <div class="weightRange3">
                                  <ValidationProvider name="price" :vid="`RBPprice-${rule_index}${price_index}`" :rules="activeShipping.id == 3 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBRprice-${rule_index}${price_index}`">Price <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="text"
                                        :id="`RBRprice-${rule_index}${price_index}`"
                                        class="form-control"
                                        :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                        v-model="listedPrice.price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                    </div>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="weightRange3" v-if="isNonCodDifferent">
                                  <ValidationProvider name="price" :vid="`RBPNONCODprice-${rule_index}${price_index}`" :rules="isNonCodDifferent &&  activeShipping.id == 3 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBPNONCODprice-${price_index}`">Price (Non-COD) <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="text"
                                        :id="`RBPNONCODprice-${rule_index}${price_index}`"
                                        class="form-control"
                                        :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                        v-model="listedPrice.non_cod_price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                    </div>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="closeRangeBtn" v-if="price_index > 1">
                                  <i class="fa fa-times" aria-hidden="true" @click="removeSubItem(item,listedPrice,price_index)"></i>
                                </div>
                                <div class="addField" v-show="price_index == (item.prices.length - 1)">
                                  <button type="button" @click.prevent="addFields(price_index, rule_index)"><span>+</span></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click.prevent="addMoreForm">+ Add Another Shipping Option</button>
                      </div>
                    </div>
                    <!-- rate by price form ends here -->

                    <!-- store pickup only form starts here -->
                    <div class="storePickUpForm" v-show="activeShipping.id == 4">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <hr v-show="rule_index > 0" />
                        <div class="cancelBtn">
                          <i class="fa fa-times" aria-hidden="true" @click="removeItem(item,rule_index)"></i>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <ValidationProvider name="store name" :rules="activeShipping.id == 4 ? {required:true,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sName + ${rule_index}`">Store Name <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`sName + ${rule_index}`"
                                  class="form-control"
                                  placeholder="Store name"
                                  v-model="item.store_name"
                                  @input="formatHandle('store name',item)"
                                  @blur="checkDuplicateShippingOption(item)"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-6">
                            <ValidationProvider name="store contact no." :rules="activeShipping.id == 4 ? {required:true,max:25} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sPhoneNo + ${rule_index}`">Store Contact No.<span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="number"
                                  :id="`sPhoneNo + ${rule_index}`"
                                  class="form-control"
                                  placeholder="Store contact no."
                                  @input="formatHandle('store contact',item)"
                                  @blur="trimHandle('store contact',item)"
                                  v-model="item.store_contact_no"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-6">
                            <ValidationProvider name="store address" :rules="activeShipping.id == 4 ? {required:true,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sLocation + ${rule_index}`">Store Address <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`sLocation + ${rule_index}`"
                                  class="form-control"
                                  placeholder="Store address"
                                  @input="formatHandle('store location',item)"
                                  @blur="trimHandle('store location',item)"
                                  v-model="item.store_address"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-6">
                            <ValidationProvider name="store city" :rules="activeShipping.id == 4 ? {required:true} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sCity + ${rule_index}`">Store City <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <multiselect 
                                  v-model="item.store_city_id" 
                                  :options="selectedCities ? selectedCities : []"
                                  deselect-label="Can't remove this value" 
                                  label="name"
                                  track-by="id"
                                  :hideSelected="false"
                                  :showNoResults="true"
                                  placeholder="Select one"
                                  :searchable="false" 
                                  :allow-empty="false"
                                >
                                  <span slot="noOptions">Cities must be selected first.</span>
                                </multiselect>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click.prevent="addMoreForm">+ Add Another Store details</button>
                      </div>
                    </div>
                    <!-- store pickup only form ends here -->

                  </div>
                </div>
              </form>
              <!-- shipping method forms ends here -->

              <!-- shipping methods block ends here -->
            </div>
            <!-- region advance information block ends here -->

            <!-- region advance settings block starts here -->
            <div class="shipping-box settingBox" id="advance-settings">
              <div class="row">
                <div class="col-md-12">
                  <h2>ADVANCE SETTINGS</h2>
                  <!-- handling fee block starts here -->
                  <div class="region-content">
                    <div class="advanceChecked">
                      <input
                        type="checkbox"
                        name="defaultUnchecked5"
                        id="defaultUnchecked5"
                        v-model="handlingFee.isActive"
                        :disabled="tourIsActive"
                      />
                      <label for="defaultUnchecked5">Add A Handling Fee To Every Order</label>
                    </div>
                    <!-- <ValidationProvider name="handling fee" :rules="handlingFee.isActive ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/} : ''" v-slot="{ errors }">
                      <div class="advanceChecked" v-if="handlingFee.isActive">
                        <input
                          type="text"
                          name="handlingfee"
                          id="handlingfee"
                          placeholder="Add Handling Fee"
                          v-model="handlingFee.fee"
                        />
                      </div>
                      <span class="text-danger" v-if="handlingFee.isActive && errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider> -->
                  </div>
                  <!-- handling fee block ends here -->

                  <div class="region-content">
                    <div class="advanceChecked">
                      <input
                        type="checkbox"
                        name="defaultUnchecked6"
                        id="defaultUnchecked6"
                        required
                        :disabled="tourIsActive"
                      />
                      <label for="defaultUnchecked6">Allow Store Pickup</label>
                    </div>
                  </div>
                  <div class="settingInfoParagraph">
                    <p>
                      Let Customers Pick Up Orders In Person As One Of The Delivery
                      Options In This Region.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- region advance settings block ends here -->

            <!-- save shipping block starts here -->
            <div class="shippingBtn">
              <button type="button" @click.prevent="goBack()" id="cancel-btn" :disabled="tourIsActive">CANCEL</button>
              <loaderBtn v-if="shippingLoader"/>
              <button 
                form="add-shipping-information" 
                button="submit" 
                @click.prevent="validate('add')" 
                id="save-region" 
                :disabled="tourIsActive"
                v-else
              >
                SAVE
              </button>
            </div>
            <!-- save shipping block ends here -->
            
          </form>

        </div>
      </div>
    </ValidationObserver>
    
    <!-- vue tour -->
    <v-tour name="addShippingTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
  
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Swal from 'sweetalert2';
import { Message, MessageBox } from "element-ui";
import subHeader from "@/components/subHeader.vue";
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from "vuex";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  metaInfo() {
    return {
      title: 'E-Commerce | Add Shipping Region | YeetCommerce',
    };
  },
  data() {
    return {
      region_name:'',

      isCodSupport: true,
      isNonCodDifferent: false,

      showDropDown: false,
      handlingFee:{
        isActive:false,
        fee:''
      },
      countriesList:[],
      statesGroup:[],
      statesLoading:false,
      citiesGroup:[],
      citiesLoading:false,
      selectedCountries: null,
      selectedStates: null,
      selectedCities: null,
      activeShipping: {
        id: 1,
        title: "Free Shipping",
        description: "Enhance customer satisfaction by offering free shipping on all orders.",
        img: "/images/free-shipping.png",
      },
      shippingOptions: [
        {
          id: 2,
          title: "Rate By Weight",
          description: "The total weight of the items determines the shipping cost of the order.",
          note: "Rate will automatically set to 0 if any of the products available in the cart have 0 weight",
          img: "/images/balance@2x.png",
        },
        {
          id: 3,
          title: "Rate By Price",
          description: "Shipping costs are calculated based on the total monetary value of the order.",
          img: "/images/cart@2x.png",
        },
        {
          id: 4,
          title: "Store Pickup Only",
          description: "Orders are available for pickup at available store locations only.",
          img: "/images/front-store@2x.png",
        },
        {
          id: 5,
          title: "Flat Rate",
          description: "Make shipping calculations easy with a fixed cost applied to each item ordered.",
          img: "/images/flat-rate@2x.png",
        },
      ],
      shippingRules: [],
      rule: null,
      shippingLoader:false,
      isTouched:false,
      isChange:false,
      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //region steps
        {
          target: '#basic-info',
          content: `<strong>Region Information</strong><br>Basic information about region can be added here.`,
          params: {
            highlight: true,
            enableScrolling: true
          }
        },
        {
          target: '#advance-info',
          content: `<strong>Region Information</strong><br>Advance information about region can be added here.`,
          params: {
            highlight: true,
            enableScrolling: true
          }
        },
        {
          target: '#cod-support-checkbox',
          content: `<strong>COD Support</strong><br>If your region supports COD payments. You can keep the option checked.`,
          params: {
            highlight: true,
            enableScrolling: true
          }
        },
        {
          target: '#non-cod-different',
          content: `<strong>Different Charges For Non-COD</strong><br>If your region have different charges for a non-COD payment. You can check the option.`,
          params: {
            highlight: true,
            enableScrolling: true
          }
        },
        {
          target: '#active-shipping-method',
          content: `<strong>Shipping Method</strong><br>Shipping method can be selected by clicking this dropdown option.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
            
          }
        },
        {
          target: '#rate-by-weight',
          content: `<strong>Pricing Details</strong><br>Pricing details against a selected shipping option can be filled using these fields.<br>
          <strong>Note:</strong> Pricing scheme can be different for different shipping options.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'right'
          }
        },
        {
          target: '#advance-settings',
          content: `<strong>Advance Settings</strong><br>Advance settings can be adjusted here.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'top'

          }
        },
        {
          target: '#save-region',
          content: `<strong>Save Shipping Region</strong><br>Shipping region can be saved by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'right'

          }
        },
        {
          target: '#cancel-btn',
          content: `<strong>Cancel</strong><br>Added information can be discarded and redirect to shipping regions list can be done by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'right'

          }
        },
      ],
      tourIsActive:false,
    }
  },
  components: {
    Multiselect,
    subHeader,
    loaderBtn
  },
  methods: {
    formatHandle(field,item){

      if(field == 'option name'){

        let tempOptionName = item.name;

        tempOptionName = tempOptionName.replace(/[^a-zA-Z0-9 -]/g, '');

        tempOptionName = tempOptionName.replace(/\s+/g, ' ');

        item.name = tempOptionName;

      }else if(field == 'delivery details'){

        let tempDeliveryDetail = item.delivery_details;

        tempDeliveryDetail = tempDeliveryDetail.replace(/[^a-zA-Z0-9 -]/g, '');

        tempDeliveryDetail = tempDeliveryDetail.replace(/\s+/g, ' ');

        item.delivery_details = tempDeliveryDetail;

      }else if(field == 'store name'){

        let tempStoreName = item.store_name;

        tempStoreName = tempStoreName.replace(/[^a-zA-Z0-9 -]/g, '');

        tempStoreName = tempStoreName.replace(/\s+/g, ' ');

        item.store_name = tempStoreName;

      }else if(field == 'store contact'){

        let tempStoreContactNo = item.store_contact_no;

        tempStoreContactNo = tempStoreContactNo.replace(/[^a-zA-Z0-9 -]/g, '');

        tempStoreContactNo = tempStoreContactNo.replace(/\s+/g, ' ');

        item.store_contact_no = tempStoreContactNo;

      }else if(field == 'store location'){

        let tempStoreAddress = item.store_address;

        tempStoreAddress = tempStoreAddress.replace(/[^a-zA-Z0-9 -]/g, '');

        tempStoreAddress = tempStoreAddress.replace(/\s+/g, ' ');

        item.store_address = tempStoreAddress;

      }

    },
    trimHandle(field,item){

      if(field == 'delivery details'){
        
        item.delivery_details = item.delivery_details.trim();

      }else if(field == 'store contact'){

        item.store_contact_no = item.store_contact_no.trim();

      }else if(field == 'store location'){
        
        item.store_address = item.store_address.trim();

      }

    },

    changeOption(option){

      let current = this.activeShipping;
      this.shippingOptions = this.shippingOptions.map((element) => {

        if(element.id == option.id){

          return current;

        }else{

          return element;

        }

      });

      this.activeShipping = option;
      this.showDropDown = false;

      if(this.activeShipping.title == "Rate By Weight"){

        Swal.fire({
          title: 'Note',
          text: this.activeShipping.note,
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: '#15223D',
          cancelButtonColor: '#15223D',
          confirmButtonText: 'OK'
        }).then((result) => {

          if(result.isConfirmed){
            
          }

        })

      }

    },
    removeItem(item,index){

      if(!this.tourIsActive){

        MessageBox.confirm(
          "Are you sure ? The action is irreversible.", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(()=>{

          if(this.shippingRules.length > 1){

            this.shippingRules.splice(index,1);

          }else{

            this.shippingRules = [];
            this.addMoreForm();

          }

        }).catch(()=>{})

      }

    },
    removeSubItem(item,subItem,subItemIndex){

      if(!this.tourIsActive){

        MessageBox.confirm(
          "Are you sure ? The action is irreversible.", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(()=>{

          if(this.activeShipping.id == 2){

            if(item.weights.length > 2){

              item.weights.splice(subItemIndex,1);

              for(let i = 0; i < item.weights.length; i++){

                if(i + 1 == item.weights.length){
                
                  item.weights[i].from = parseFloat(parseFloat(item.weights[i - 1].to)  + 0.01).toFixed(2);
                  item.weights[i].to = "";

                }else if(i != 0){
                  
                  item.weights[i].from = parseFloat(parseFloat(item.weights[i - 1].to)  + 0.01).toFixed(2);
                  item.weights[i].to = parseFloat(parseFloat(item.weights[i].from)  + 0.01).toFixed(2);
                
                }

              }

            }

          }else if(this.activeShipping.id == 3){

            if(item.prices.length > 2){

              item.prices.splice(subItemIndex,1);

              for( let i = 0; i < item.prices.length; i++){

                if(i + 1 == item.prices.length){
                
                  item.prices[i].from = parseFloat(parseFloat(item.prices[i - 1].to)  + 0.01).toFixed(2);
                  item.prices[i].to = "";

                }else if(i != 0){
                  
                  item.prices[i].from = parseFloat(parseFloat(item.prices[i - 1].to)  + 0.01).toFixed(2);
                  item.prices[i].to = parseFloat(parseFloat(item.prices[i].from)  + 0.01).toFixed(2);
                
                }

              }

            }

          }else if((this.activeShipping.id == 4) || (this.activeShipping.id == 5)){

          }

        }).catch(()=>{})

      }

    },
    addFields(child_index, rule_index){

      if(this.activeShipping.id == 2){

        if(parseFloat(this.shippingRules[rule_index].weights[child_index - 1].to) > 0){

          let obj = {
            from: parseFloat(parseFloat(this.shippingRules[rule_index].weights[child_index].from)  + 0.01).toFixed(2),
            to: '',
            price: '' ,
            non_cod_price:''
          }

          this.shippingRules[rule_index].weights[child_index].to = obj.from;
          this.shippingRules[rule_index].weights.push(obj);
          this.shippingRules[rule_index].weights[this.shippingRules[rule_index].weights.length - 1].from = (parseFloat(obj.from) + 0.01).toFixed(2);

        }else{

          this.$notify({
            type: "warning",
            title: "Range Required",
            message: "Fields of previous range are required.",
          });

        }

      }else if(this.activeShipping.id == 3){
        
        if(parseFloat(this.shippingRules[rule_index].prices[child_index - 1].to) > 0){

          let obj = {
            from: parseFloat(parseFloat(this.shippingRules[rule_index].prices[child_index].from) + 0.01).toFixed(2),
            to: '',
            price: '',
            non_cod_price:'',
          }

          this.shippingRules[rule_index].prices[child_index].to = obj.from;
          this.shippingRules[rule_index].prices.push(obj);

          this.shippingRules[rule_index].prices[this.shippingRules[rule_index].prices.length - 1].from = (parseFloat(obj.from) + 0.01).toFixed(2);

        }else{

          this.$notify({
            type: "warning",
            title: "Range Required",
            message: "Fields of previous range are required.",
          });

        }

      }

    },
    inpFrom(child_index, rule_index){

      if(this.activeShipping.id == 2){

        if(this.shippingRules[rule_index].weights[child_index-1]){

          this.shippingRules[rule_index].weights[child_index-1].to = parseFloat(parseFloat(this.shippingRules[rule_index].weights[child_index].from) - 0.01).toFixed(2);
        
        }

      }else if(this.activeShipping.id == 3){

        if(this.shippingRules[rule_index].prices[child_index-1]){

          this.shippingRules[rule_index].prices[child_index-1].to = parseFloat(parseFloat(this.shippingRules[rule_index].prices[child_index].from) - 0.01).toFixed(2);
        
        }

      }

    },
    inpTo(child_index, rule_index){

      if(this.activeShipping.id == 2){

        if(this.shippingRules[rule_index].weights[child_index+1]){

          this.shippingRules[rule_index].weights[child_index+1].from = parseFloat(parseFloat(this.shippingRules[rule_index].weights[child_index].to) + 0.01).toFixed(2);
        
        }

      }else if(this.activeShipping.id == 3){

        if(this.shippingRules[rule_index].prices[child_index+1]){

          this.shippingRules[rule_index].prices[child_index+1].from = parseFloat(parseFloat(this.shippingRules[rule_index].prices[child_index].to) + 0.01).toFixed(2);
        
        }

      }

    },
    addMoreForm(){

      if(!this.tourIsActive){

        let newRule = {}
        if(this.activeShipping.id == 2){

          newRule = {
            name: "",
            delivery_details: "",
            isActive: 1,
            weights: [
              {
                from: 0.0,
                to: 1,
                price: "",
                non_cod_price: "",
              },
              {
                from: 1,
                to: '',
                price: "",
                non_cod_price: "",
              },
            ],
          };

        }else if(this.activeShipping.id == 3){

          newRule = {
            name: "",
            delivery_details: "",
            isActive: 1,
            prices: [
              {
                from: 0.0,
                to: 1,
                price: "",
                non_cod_price: "",
              },
              {
                from: 1,
                to: '',
                price: "",
                non_cod_price: "",
              },
            ],
          };

        }else if(this.activeShipping.id == 4){

          newRule = {
            store_name: "",
            store_contact_no: "",
            store_address: "",
            store_city_id: "",
            isActive: 1,
          };

        }else if(this.activeShipping.id == 5){

          newRule = {
            name: "",
            delivery_details:'',
            price: '',
            non_cod_price:'',
            isActive: 1,
          };

        }

        this.shippingRules.push(_.cloneDeep(newRule));

      }

    },
    async validate(action){

      if(action == "add"){

        this.$refs.addShippingObserver.validate().then(success => {

          if(!success){

            const errors = Object.entries(this.$refs.addShippingObserver.errors)
            .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

            this.$refs.addShippingObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center"});

          }else{

            this.addRegion();

          }

        });

      }

    },
    checkDuplicateShippingOption(item){

      if(this.activeShipping.id == 2){
        //rate by weight
        
        item.name = item.name.trim();

        if(item.name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].name == item.name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Option Name Already Exists',
              message: "An option already exists with the entered name. Kindly enter a unique one.",
            });

            item.name = ''

          }

        }

      }else if(this.activeShipping.id == 3){
        //rate by price
        
        item.name = item.name.trim();

        if(item.name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].name == item.name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Option Name Already Exists',
              message: "An option already exists with the entered name. Kindly enter a unique one.",
            });

            item.name = ''

          }

        }

      }else if(this.activeShipping.id == 4){
        //store pickup only
        
        item.store_name = item.store_name.trim();

        if(item.store_name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].store_name == item.store_name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Store Name Already Exists',
              message: "A store already exists with the entered name. Kindly enter a unique one.",
            });

            item.store_name = ''

          }

        }

      }else if(this.activeShipping.id == 5){
        //flat rate

        item.name = item.name.trim();

        if(item.name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].name == item.name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Option Name Already Exists',
              message: "An option already exists with the entered name. Kindly enter a unique one.",
            });

            item.name = ''

          }

        }

      }

    },
    async addRegion(){
      
      let formData = new FormData();

      formData.append('name', this.region_name.trim());
      formData.append('is_cod_supported', this.isCodSupport ? 1 : 0);
      formData.append('is_non_cod_different', this.isNonCodDifferent ? 1 : 0);

      formData.append('cities', JSON.stringify(this.selectedCities.map((city) => {return city.id})));

      formData.append('shipping_method_type',this.activeShipping.title);

      if(this.activeShipping.id == 4){

        let tempRules = _.cloneDeep(this.shippingRules);

        tempRules.map((rule) => {

          rule.store_city_id = rule.store_city_id.id

          return rule;

        });

        formData.append('shipping_rules', JSON.stringify(tempRules));

      }else{

        formData.append('shipping_rules', JSON.stringify(this.shippingRules));

      }

      this.shippingLoader = true
      try{
        let res = await this.$axios.post('/shipping/region/add', formData);
        if(res.data.status_code == '1012'){
          
          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });
          
          this.region_name = ''
          this.selectedCities = null
          this.shippingRules = []

          this.$store.dispatch("shipping_module/fetchStoreRegionsRequest",{ size: 12, page: 1, loadData: 'new'});

          this.$router.push('/shipping');
          
        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.name){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.name[0],
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.shippingLoader = false

      }
      
    },
    toggleDropDown(){

      if(!this.tourIsActive){

        this.showDropDown = !this.showDropDown

      }

    },
    goBack(){

      if(this.selectedCities || this.shippingRules.length > 0 || this.isTouched){

        this.isGoBack = true
        this.$router.push('/shipping');

      }else{

        this.isGoBack = true
        this.$router.push('/shipping');

      }

    },
    async loadStates(){

      let countryIds = [];

      this.statesGroup = [];

      this.selectedCountries.forEach(country => {

        countryIds.push(country.id);

        let obj = {
          country_id:country.id,
          country_name: country.name + " (Selecting this option will select all the states available in this country.)",
          states:[]
        }

        this.statesGroup.push(obj);

      });

      this.statesLoading = true;      
      try{
        let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}});
        if(res.data.status_code == "1010"){

          this.statesList = _.cloneDeep(res.data.states);

          if(this.statesGroup.length > 0){

            this.statesGroup.forEach(group => {

              res.data.states.forEach(state =>{

                if(state.country_id == group.country_id){

                  group.states.push(state);

                }

              });

            });

          }

        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        this.statesLoading = false;      

      }

    },
    async loadCities(){

      let stateIds = [];

      this.citiesGroup = [];

      this.selectedStates.forEach(state => {

        stateIds.push(state.id);

        let obj = {
          state_id:state.id,
          state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
          cities:[]
        }

        this.citiesGroup.push(obj);

      });

      this.citiesLoading = true;
      try{
        let res = await this.$axios.get('/state/cities/all',{params:{states:stateIds}});
        if(res.data.status_code == "1010"){

          this.citiesList = _.cloneDeep(res.data.cities);
          
          this.citiesGroup.forEach(group => {

            res.data.cities.forEach(city =>{

              if(city.state_id == group.state_id){

                group.cities.push(city);

              }

            });

          });

        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        this.citiesLoading = false;

      }
      
    },
    countryRemoval(item,i){

      if(this.selectedStates && this.selectedStates.length > 0){

        this.selectedStates = this.selectedStates.filter(state =>{

          if(state.country_id != item.id){

            return state;

          }

        });

      }

      if(this.selectedCities && this.selectedCities.length > 0){

        this.selectedCities = this.selectedCities.filter(city => {

          if(city.country_id != item.id){

            return city;

          }

        });

      }
      
    },
    stateRemoval(item,i){

      if(this.selectedCities && this.selectedCities.length > 0){

        this.selectedCities = this.selectedCities.forEach(city => {

          if(city.state_id != item.id){

            return city;

          }

        });

      }

    },
    cityRemoval(item,i){

      if(this.activeShipping.id == 4){

        this.shippingRules.forEach((rule) => {

          if(rule.store_city_id.id == item.id){

            rule.store_city_id = "";

          }

        });

      }


    },
    //tour methods
    startAddShippingTour(){

      this.$tours.addShippingTour.start();

      this.tourIsActive = true;

    },
    handleNextStep(currentStep){

      if(this.$tours.addShippingTour.currentStep + 1 == 9){

        this.activeShipping = {
          id: 5,
          title: "Flat Rate",
          description: "Apply The Same Rate For Customer's Entire Order",
          img: "/images/flat-rate@2x.png",
        }

      }

    },
    handlePreviousStep(currentStep){

      if(this.$tours.addShippingTour.currentStep == 9){

        this.activeShipping = {
          id: 1,
          title: "Free Shipping",
          description: "Offer Free Shipping To Your Customers",
          img: "/images/free-shipping.png",
        }

      }

    },
    handleTourStop(){

      // this.activeShipping = {
      //   id: 1,
      //   title: "Free Shipping",
      //   description: "Offer Free Shipping To Your Customers",
      //   img: "/images/free-shipping.png",
      // }

      // this.changeOption(this.activeShipping);

      this.tourIsActive = false;

    },
    handleTourFinish(){

      // this.activeShipping = {
      //   id: 1,
      //   title: "Free Shipping",
      //   description: "Offer Free Shipping To Your Customers",
      //   img: "/images/free-shipping.png",
      // }

      // this.changeOption(this.activeShipping);

      this.tourIsActive = false;

    },
    handleTourSkip(){

      // this.activeShipping = {
      //   id: 1,
      //   title: "Free Shipping",
      //   description: "Offer Free Shipping To Your Customers",
      //   img: "/images/free-shipping.png",
      // }

      // this.changeOption(this.activeShipping);

      this.tourIsActive = false;

    },
  },
  async beforeMount(){

    if(!this.storeCountries || this.storeCountries.length == 0){

      this.$store.dispatch('countries_module/fetchStoreCountriesRequest');

    }else{

      this.countriesList = this.storeCountries;

    }

  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
      storeCountries:'countries_module/countries',
    }),
  },
  watch: {
    storeCountries:{
      handler:function(value){

        this.countriesList = value;

      },deep:true
    },
    selectedCountries:{
      handler:function(value){

        if(value && value.length > 0){

          this.loadStates();

          this.isTouched = true;

        }else{

          this.statesGroup = [];
          this.selectedStates = null;
          this.citiesGroup = [];
          this.selectedCities = null;

          this.isTouched = false;

        }

      },
      deep: true,
    },
    selectedStates:{
      handler:function(value){

        if(value && value.length > 0){

          this.loadCities();

          this.isTouched = true;

        }else{

          this.citiesGroup = [];
          this.selectedCities = null;
          this.isTouched = false;

        }
        
      },
      deep: true,
    },
    activeShipping:{
      handler:function(value){

        if(value.id == 2){

          this.rule = {
            name: "",
            delivery_details: "",
            isActive: 1,
            weights: [
              {
                from: 0.0,
                to: 1,
                price: "",
                non_cod_price:'',
              },
              {
                from: 1,
                to: '',
                price: "",
                non_cod_price:'',
              },
            ],
          };

        }else if(value.id == 3){

          this.rule = {
            name: "",
            delivery_details: "",
            isActive: 1,
            prices: [
              {
                from: 0.0,
                to: 1,
                price: "",
                non_cod_price:"",
              },
              {
                from: 1,
                to: '',
                price: "",
                non_cod_price:"",
              },
            ],
          };

        }else if(this.activeShipping.id == 4){

          this.rule = {
            store_name: "",
            store_contact_no: "",
            store_address: "",
            store_city_id: "",
            isActive: 1,
          };

        }else if(this.activeShipping.id == 5){

          this.rule = {
            name: "",
            delivery_details:'',
            price: '',
            non_cod_price:'',
            isActive: 1,
          };

        }

        this.shippingRules = [_.cloneDeep(this.rule)];

        if(value){

          this.isTouched = true;

        }else{

          this.isTouched = false;

        }

      },
      deep: true,
    },
    'region_name':{
      handler:function(value){

        if(value){

          this.isTouched = true;

        }else{

          this.isTouched = false;

        }
        
      }
    },

  },
  beforeRouteLeave(to, from, next) {

    if((this.region_name || this.selectedCities || this.shippingRules.length > 0 || this.isGoBack) && to.fullPath != '/login'){
      
      MessageBox.confirm(
        'You have unsaved changes, Proceeding will discard them',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Process',
          cancelButtonText: 'Stay',
        }
      ).then(()=>{

        next();

      }).catch(()=>{
        
      });

    }else{

      next();

    }

  },
};
</script>
<style scoped>
  .multiselect {
    color: black;
    margin-bottom:0px !important;
  }
</style>